<template>
    <div class="mod-config">
        <div style="display: flex;width: 100%;">
            <div style="width: 15%; margin: 20px;">
                <h3>组织树:</h3>
                <el-tree
                    ref="orgTree"
                    :props="org_props"
                    node-key="dimensionUnitId"
                    :load="loadOrg"
                    :expand-on-click-node="false"
                    @node-click="orgClick"
                    :highlight-current="true"
                    lazy>
                </el-tree>
            </div>
            <div style="width: 40%; margin: 20px 20px 20px 0;">
                <p>
                    组织信息: <span style="font-weight: bolder;">{{orgTitle}}</span>
                    <el-button style="float: right; margin-right: 20px;" type="primary" size="small" class="btn"
                               @click="addUnitUser()">+ 添加用户
                    </el-button>
                </p>
                <el-table
                    :data="dataList"
                    border
                    v-loading="dataListLoading"
                    :highlight-current-row="true"
                    @selection-change="userSelectionChangeHandle"
                    @row-click="clickUser"
                    style="width: 100%;margin-top: 30px;">
                    <el-table-column
                        prop="realName"
                        header-align="center"
                        align="center"
                        label="姓名">
                    </el-table-column>
                    <el-table-column
                        prop="username"
                        header-align="center"
                        align="center"
                        label="用户名">
                    </el-table-column>
                    <el-table-column
                        prop="isLeader"
                        header-align="center"
                        align="center"
                        label="是否负责人">
                        <template slot-scope="scope">
            <span v-if="scope.row.isLeader==1">
                      是</span>
                            <span v-else>
                      不是</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="description"
                        header-align="center"
                        align="center"
                        label="描述信息">
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                        <template slot-scope="scope">
                            <div
                                class="search-table-icon search-table-icon_edit"
                                @click="editUnitUser(scope.row)"
                                type="text"
                            ></div>
                            <div
                                class="search-table-icon search-table-icon_delete"
                                @click="deleteHandle(scope.row.id)"
                                type="text"
                            ></div>
<!--                            <el-button type="primary" size="small" @click="editUnitUser(scope.row)">修改</el-button>-->
<!--                            <el-button type="primary" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <div style="width: 40%; margin: 20px 20px 20px 0">
                <p>
                    用户信息: <span style="font-weight: bolder;">{{userName}}</span>
<!--                    <el-button class="btn" style="float: right; margin-left: 10px" type="primary" size="small" @click="deletePositionHandle()">删除</el-button>-->
<!--                    <el-button class="btn" style="float: right; margin-left: 10px" type="primary" size="small" @click="editPositionUser()">修改</el-button>-->
                    <el-button class="btn" style="float: right; margin-right: 20px" type="primary" size="small" @click="addPosition()">+ 添加职务</el-button>
                </p>
                <el-table
                    :data="positionList"
                    border
                    v-loading="positionListLoading"
                    @selection-change="positionSelectionChangeHandle"
                    style="width: 100%;margin-top: 30px;">
                    <!--<el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                    </el-table-column>-->
                    <el-table-column
                        prop="name"
                        header-align="center"
                        align="center"
                        label="职位">
                    </el-table-column>
                    <el-table-column
                        prop="isMajor"
                        header-align="center"
                        align="center"
                        label="是否主职位">
                        <template slot-scope="scope">
                  <span v-if="scope.row.isMajor==1">
                      是</span>
                            <span v-else>
                      不是</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="description"
                        header-align="center"
                        align="center"
                        label="描述信息">
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                        <template slot-scope="scope">
                            <div
                                class="search-table-icon search-table-icon_edit"
                                @click="editPositionUser(scope.row)"
                                type="text"
                            ></div>
                            <div
                                class="search-table-icon search-table-icon_delete"
                                @click="deletePositionHandle(scope.row.id)"
                                type="text"
                            ></div>
                            <!--<el-button type="primary" size="small" @click="editPositionUser(scope.row)">修改</el-button>
                            <el-button type="primary" size="small" @click="deletePositionHandle(scope.row.id)">删除</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        <user-position v-if="PositionIsShow" ref="userPosition" @refreshDataList="loadPositionData"></user-position>
        <el-dialog
            title="修改"
            :visible.sync="editUnitUserShow"
            width="30%">
            <el-form :model="editUnitUserForm" ref="editUnitUserForm" label-width="120px">
                <el-form-item label="姓名" prop="realName">
                    <el-input v-model="editUnitUserForm.realName" placeholder="姓名" readOnly></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="editUnitUserForm.username" placeholder="用户名" readOnly></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input v-model="editUnitUserForm.description" placeholder="描述" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="是否负责人" prop="isLeader">
                    <el-switch v-model="editUnitUserForm.isLeader"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editUnitUserShow = false">取 消</el-button>
    <el-button type="primary" @click="updateUnitUser">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
            title="修改"
            :visible.sync="editPositionUserShow"
            width="30%">
            <el-form :model="editPositionUserForm" ref="editPositionUserForm" label-width="120px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="editPositionUserForm.name" placeholder="名称" readOnly></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input v-model="editPositionUserForm.description" placeholder="描述"></el-input>
                </el-form-item>
                <el-form-item label="是否主职务" prop="isMajor">
                    <el-switch v-model="editPositionUserForm.isMajor"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editPositionUserShow = false">取 消</el-button>
    <el-button type="primary" @click="updatePositionUser">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
import AddOrUpdate from './orgUnitUser-add-or-update';
import UserPosition from './userPosition-add-or-update';

export default {
    data() {
        return {
            orgTitle: '',
            userName: '',
            org_props: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            editUnitUserForm: {
                id: '',
                name: '',
                username: '',
                isLeader: '',
                description: ''
            },
            editPositionUserForm: {
                id: '',
                name: '',
                isMajor: '',
                description: ''
            },
            dataForm: {
                key: ''
            },
            unitId: '',
            editUnitUserShow: false,
            editPositionUserShow: false,
            currentUserIds: [],
            currentPositionIds: [],
            dataList: [],
            positionList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            positionListLoading: false,
            addOrUpdateVisible: false,
            PositionIsShow: false,
            currentUserId: '',
            userListSelections: [],
            orgListSelections: [],
            positionSelections: [],
        };
    },
    components: {
        AddOrUpdate, UserPosition
    },
    created() {
    },
    methods: {
        editUnitUser(row) {
            if (!row) {
                return;
            }
            const _row = row;
            this.editUnitUserForm = _row;
            this.editUnitUserForm.isLeader = _row.isLeader === 1 ? true : false;
            this.editUnitUserShow = true;
        },
        updateUnitUser() {
            this.editUnitUserForm.isLeader = this.editUnitUserForm.isLeader ? 1 : 0;
            this.$client.updateUnitUser(this.editUnitUserForm).then(() => {
                this.editUnitUserShow = false;
                this.getDataList();
            });
        },
        editPositionUser(row) {
            if (!row) {
                return;
            }
            const _row = row;
            this.editPositionUserForm = _row;
            this.editPositionUserForm.isMajor = _row.isMajor === 1 ? true : false;
            this.editPositionUserShow = true;
        },
        updatePositionUser() {
            this.editPositionUserForm.isMajor = this.editPositionUserForm.isMajor ? 1 : 0;
            this.$client.updatePositionUser(this.editPositionUserForm).then(() => {
                this.editPositionUserShow = false;
                this.loadPositionData();
            });
        },
        clickUser(row) {
            this.currentUserId = row.userId;
            this.userName = row.username;
            this.loadPositionData();
        },
        loadPositionData() {
            this.$client.getPositionListByUserIdAndOrgId({
                userId: this.currentUserId,
                orgId: this.unitId
            }).then((data) => {
                this.positionList = data.list;
                this.currentPositionIds = [];
                for (const item of this.positionList) {
                    this.currentPositionIds.push(item.stationId);
                }
            });
        },
        orgClick(data) {
            this.unitId = data.id;
            this.orgTitle = data.name;
            this.pageIndex = 1;
            this.getDataList();
            this.positionList = [];
            this.currentPositionIds = [];
            this.userName = '';
        },
        loadOrg(node, resolve) {
            this.$client.getOrgUnitListByParent({parent: node.data ? node.data.id : '-1'}).then((data) => {
                if (data && data.code === 0) {
                    const list = [];
                    for (const item of data.list) {
                        item.isLeaf = item.isLeaf === 1 ? true : false;
                        list.push(item);
                    }
                    resolve(list);
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        addUnitUser() {
            if (!this.unitId || this.unitId === '') {
                this.$message.error('请选择所属组织!');
                return;
            }
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(this.unitId, this.orgName, this.currentUserIds);
            });
        },
        addPosition() {
            if (!this.currentUserId || this.currentUserId === '') {
                this.$message.error('请选择用户!');
                return;
            }
            this.PositionIsShow = true;
            this.$nextTick(() => {
                this.$refs.userPosition.init(this.currentUserId, this.unitId, this.currentPositionIds);
            });
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.$client.getOrgUnitUserListByOrgId({
                current: this.pageIndex,
                size: this.pageSize,
                unitId: this.unitId
            }).then((data) => {
                if (data && data.code === 0) {
                    this.dataList = data.data.records;
                    this.currentUserIds = [];
                    for (const item of this.dataList) {
                        this.currentUserIds.push(item.userId);
                    }
                    this.totalPage = data.data.total;
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        // 删除
        deleteHandle(id) {
            if (!id) {
                return;
            }
            const ids = [];
            ids.push(id);
            this.$confirm(`确定删除选中用户?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delOrgUnitUser(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                            }
                        });
                        this.getDataList();
                        this.positionList = [];
                        this.currentPositionIds = [];
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        // 删除
        deletePositionHandle(id) {
            if (!id) {
                return;
            }
            const ids = [];
            ids.push(id);
            this.$confirm(`确定删除选中数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delOrgPositionUser(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                            }
                        });
                        this.loadPositionData();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        userSelectionChangeHandle(val) {
            this.userListSelections = val;
        },
        positionSelectionChangeHandle(val) {
            this.positionSelections = val;
        },
        preValidate(isSingle, selections) {
            if (selections == null || selections.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (selections.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        }
    }
};
</script>
<style lang="less">
    .btn{
        border-radius: 4px;
    }
</style>
