<template>
  <el-dialog
    title="新增"
    :close-on-click-modal="false"
    :visible.sync="visible">

    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getPositionDataList()">
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="请输入名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getPositionDataList()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="名称">
      </el-table-column>
      <el-table-column
        prop="code"
        header-align="center"
        align="center"
        label="编码">
      </el-table-column>
      <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="描述信息">
        <template slot-scope="scope">
          <el-input v-model="scope.row.description"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="isMajor"
        header-align="center"
        align="center"
        label="是否主职位">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isMajor"></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
    background
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="addPosition()">添加</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            userId: '',
            dataList: [],
            selectedDataList: [],
            currentPositionIds: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataForm: {
                key: '',
                unitId: '',
            }
        };
    },
    methods: {
        init(userId, unitId, currentPositionIds) {
            this.visible = true;
            this.$nextTick(() => {
                this.userId = userId;
                this.dataForm.unitId = unitId;
                this.currentPositionIds = currentPositionIds;
                this.getPositionDataList();
            });
        },
        // 多选
        selectionChangeHandle(val) {
            this.selectedDataList = val;
        },
        // 获取数据列表
        getPositionDataList() {
            this.dataListLoading = true;
            this.$client.getPositionListNotIn({
                current: this.pageIndex,
                size: this.pageSize,
                key: this.dataForm.key,
                unitId: this.dataForm.unitId,
                positionIds: this.currentPositionIds.join(',')
            }).then((data) => {
                if (data && data.code === 0) {
                    const list = [];
                    for (const item of data.data.records) {
                        item.isMajor = false;
                        item.description = '';
                        list.push(item);
                    }
                    this.dataList = list;
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getPositionDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getPositionDataList();
        },
        // 表单提交
        addPosition() {
            if (this.selectedDataList.length === 0) {
                this.$message.error('请选择职务!');
                return;
            }
            this.$client.savePositionUser({
                userId: this.userId,
                list: this.selectedDataList
            }).then(() => {
                this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                    }
                });
                this.visible = false;
                this.$emit('refreshDataList');
            });
        }
    }
};
</script>
