<template>
  <el-dialog
    title="新增"
    :close-on-click-modal="false"
    :visible.sync="visible">

    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getUserDataList()">
      <el-form-item>
        <el-input v-model="dataForm.name" placeholder="姓名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getUserDataList()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="realName"
        header-align="center"
        align="center"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="描述信息">
        <template slot-scope="scope">
          <el-input v-model="scope.row.description"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="isLeader"
        header-align="center"
        align="center"
        label="是否负责人">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isLeader"></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
    background
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="addUser()">添加</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            unitId: '',
            orgName: '',
            dataList: [],
            selectedDataList: [],
            currentUserIds: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataForm: {
                name: ''
            }
        };
    },
    methods: {
        init(unitId, orgName, currentUserIds) {
            this.visible = true;
            this.$nextTick(() => {
                this.unitId = unitId;
                this.orgName = orgName;
                this.currentUserIds = currentUserIds;
                this.getUserDataList();
            });
        },
        // 多选
        selectionChangeHandle(val) {
            this.selectedDataList = val;
        },
        // 获取数据列表
        getUserDataList() {
            this.dataListLoading = true;
            this.$client.getUserListNotIn({
                current: this.pageIndex,
                size: this.pageSize,
                name: this.dataForm.name,
                //userIds: this.currentUserIds.join(',')
                userIds: this.currentUserIds,
            }).then((data) => {
                if (data && data.code === 0) {
                    const list = [];
                    for (const item of data.data.records) {
                        item.isLeader = false;
                        item.description = '';
                        list.push(item);
                    }
                    this.dataList = list;
                    this.totalPage = data.data.total;
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getUserDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getUserDataList();
        },
        // 表单提交
        addUser() {
            if (this.selectedDataList.length === 0) {
                this.$message.error('请选择用户!');
                return;
            }
            this.$client.saveUnitUser({
                unitId: this.unitId,
                list: this.selectedDataList
            }).then(() => {
                this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                    }
                });
                this.visible = false;
                this.$emit('refreshDataList');
            });
        }
    }
};
</script>
